//@import "styles/shaka/controls.scss";
@import url('https://fonts.googleapis.com/css?family=Orbitron|Oswald|Roboto');


:root {

	--24-dark-color: #06080d;
	--24-dim-color: #111;
	--24-shaded-color: #222;
	--24-light-color: #2d2d2d;
	--24-base-color: #3f3f3f;
	--24-shine-color: #a4a4a4;

	--24-accent-base-color: #085049; //mlb #082050
	--24-accent-dim-color: #112c2c;
	--24-accent-burnt-color: #11181c;

	--24-important-red-color: #880b0b;
	--24-glowing-green-color: #0f0;
	--24-premium-gold-color: #ffbf00;

	--24-changing-blue-color: #005797;
	--24-concluded-blue-color: #001f35; //#002a48;
	--24-burnt-blue-color: #001524;

	/*body.sport-mlb {
		--24-dark-color: #06080d;
		--24-dim-color: #1c1c1c;
		--24-shaded-color: #222;
		--24-light-color: #00022d;
		--24-base-color: #000627;
		--24-shine-color: #ffffff;
		--24-accent-base-color: #203b14;
		--24-accent-dim-color: #1e1e1e;
		--24-accent-burnt-color: #4d4d4d;
		--24-important-red-color: #880b0b;
		--24-glowing-green-color: #0f0;
		--24-premium-gold-color: #ffbf00;
		--24-changing-blue-color: #046b00;
		--24-concluded-blue-color: #091737;
		--24-burnt-blue-color: #fb00eb;
	}*/


	//My Theme
	&.theme-queueblue {
		--24-dark-color: #000;
		--24-dim-color: #00050d;
		--24-shaded-color: #004b53;
		--24-light-color: #032b31;
		--24-base-color: #013537;
		--24-shine-color: #9bffff;
		--24-accent-base-color: #1f5b45;
		--24-accent-dim-color: #1e350e;
		--24-accent-burnt-color: #0f1a08;
		--24-important-red-color: #710035;
		--24-glowing-green-color: #00ffcf;
		--24-premium-gold-color: #26ffa3;
		--24-changing-blue-color: #2f78d7;
		--24-concluded-blue-color: #072555;
		--24-burnt-blue-color: #050b1a;
	}

	&.theme-fabulous {
		--24-dark-color: #790161;
		--24-dim-color: #200210;
		--24-shaded-color: #350723;
		--24-light-color: #44022a;
		--24-base-color: #2d002c;
		--24-shine-color: #a4a4a4;

		--24-accent-base-color: #2e1c60;
		--24-accent-dim-color: #241848;
		--24-accent-burnt-color: #0e091e;

		--24-important-red-color: #f00;
		--24-glowing-green-color: #0f0;
		--24-premium-gold-color: #00ff93;

		--24-changing-blue-color: #007497;
		--24-concluded-blue-color: #002e35;
		--24-burnt-blue-color: #001f24;
	}


	&.theme-blackrose {
		--24-dark-color: #1a0505;
		--24-dim-color: #151414;
		--24-shaded-color: #1a0505;
		--24-light-color: #4a1818;
		--24-base-color: #863c3c;
		--24-shine-color: #000000a8;
		--24-accent-base-color: #1c0f0f;
		--24-accent-dim-color: #914444;
		--24-accent-burnt-color: #281414a8;
		--24-important-red-color: #710035;
		--24-glowing-green-color: #f8fffe;
		--24-premium-gold-color: #cea500;
		--24-changing-blue-color: #970000;
		--24-concluded-blue-color: #170101;
		--24-burnt-blue-color: #1a0505;
	}


	&.theme-evergreen {
		--24-dark-color: #000;
		--24-dim-color: #051302;
		--24-shaded-color: #2b5110;
		--24-light-color: #172f07;
		--24-base-color: #335e14;
		--24-shine-color: #9bc1ff;
		--24-accent-base-color: #284f0d;
		--24-accent-dim-color: #1e350e;
		--24-accent-burnt-color: #000;
		--24-important-red-color: #240916;
		--24-glowing-green-color: #00ffcf;
		--24-premium-gold-color: #00ff93;
		--24-changing-blue-color: #076411;
		--24-concluded-blue-color: #050e11;
		--24-burnt-blue-color: #788a91;
	}

	&.theme-cherry {
		--24-dark-color: #000;
		--24-dim-color: #130202;
		--24-shaded-color: #511010;
		--24-light-color: #2f0707;
		--24-base-color: #5e1414;
		--24-shine-color: #9bc1ff;
		--24-accent-base-color: #4f0d0d;
		--24-accent-dim-color: #350e0e;
		--24-accent-burnt-color: #000;
		--24-important-red-color: #240916;
		--24-glowing-green-color: #f00;
		--24-premium-gold-color: #f00;
		--24-changing-blue-color: #640707;
		--24-concluded-blue-color: #5e1414;
		--24-burnt-blue-color: #788a91;
	}

	&.theme-flashbang {
		--24-dark-color: #000;
		--24-dim-color: #000;
		--24-shaded-color: #b9b0b0;
		--24-light-color: #444040;
		--24-base-color: #736969;
		--24-shine-color: #f0f6ff;
		--24-accent-base-color: #cccccc2b;
		--24-accent-dim-color: #86868670;
		--24-accent-burnt-color: #000;
		--24-important-red-color: #222122;
		--24-glowing-green-color: #00ffcf;
		--24-premium-gold-color: #00ff93;
		--24-changing-blue-color: #747574;
		--24-concluded-blue-color: #171717;
		--24-burnt-blue-color: #788a91;
	}

}


* { box-sizing: border-box; } // Normalizing
html, body { min-height: 100%; }
body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	background: var(--24-base-color); color: white;
}
p { margin: 0; }


button {
	text-decoration: none;
	display: inline-block;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	border: 1px solid transparent;
	border-radius: .25rem;
	background: none;
}
*:focus { // Mostly for links
	outline: none; }
*::-moz-focus-inner { // Mostly For buttons
	border: 0; }

input[type="text"], input[type="username"], input[type="password"] {
	border-radius: 2px;
	background: white;
	text-decoration: none;
	vertical-align: middle;
	border: 1px solid;
	padding: 3px 5px;
}

@keyframes rotating {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}
@keyframes ccw_rotating {
  0%   { transform: rotate(359deg); }
  100% { transform: rotate(0deg); }
}
@keyframes hue_rotating {
	0%   { transform: rotate(0deg);   }
	100% { transform: rotate(359deg); }
}
@keyframes marquee_left {
  0%   { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}
@keyframes marquee_left_rev {
  0%   { transform: translateX(+100%); }
  100% { transform: translateX(0%);    }
}

@keyframes hue_sway {
	0%   { filter: hue-rotate(0deg); }
	50%  { filter: hue-rotate(50deg); }
	100% { filter: hue-rotate(0deg); }
}

.rotating {
	font-size: 1em;
	vertical-align: middle;
	display: inline-block;

	animation: rotating 1s linear infinite;
}

.premium-purchase {
	color: #03b8ff;
	text-decoration: none;
	font-family: Roboto;
	cursor: pointer;
	&:hover { text-decoration: underline; }
}
.warn-red {
	color: #ff0202;
	font-family: "Oswald";
	font-size: 12px;
}
.warn-orange {
	color: #ff4902;
	font-family: "Oswald";
	font-size: 12px;
}


iframe.proxy-frame {
	position: absolute;
	left: 0; top: 0; border: none;
}

.flag > img.emoji {
	height: 20px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
